import { useRequest } from 'ahooks';
import { Button, Toast } from 'antd-mobile';
import { useNavigate } from "react-router-dom";
import { postGroupBuyOrderPublishService } from '../services';
import { useCartStore } from '../stores/cart';
import { useGroupBuyStore } from '../stores/groupbuy';
import { useCurrentUserStore } from '../stores/user';

function GroupBuyButton() {
  const { user, refreshUserAsync } = useCurrentUserStore();
  const cart = useCartStore((state) => state.cart);
  const navigate = useNavigate();
  const { currentGroupBuyOutTradeNo, setCurrentGroupBuyOutTradeNo, setDataKey } =
    useGroupBuyStore();
  const { run: publishOrder, loading } = useRequest(postGroupBuyOrderPublishService, {
    manual: true,
    async onSuccess({ data, status, msg }) {
      if (status !== 200) {
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }
      await refreshUserAsync();
      setCurrentGroupBuyOutTradeNo(data.out_trade_no);
      setDataKey('owner');
      Toast.show({
        icon: 'success',
        content: msg,
      });
      navigate(`/groupbuy/${data.out_trade_no}`);
    },
  });

  if (currentGroupBuyOutTradeNo)
    return (
      <Button
        onClick={() => navigate(`/groupbuy/${currentGroupBuyOutTradeNo}`)}
        className="groupbuy-button"
        size="mini"
        shape="rounded"
        color="primary"
        fill="outline"
      >
        拼单进行中
      </Button>
    );

  return (
    // <Button
    //   onClick={() => (user ? publishOrder({ cart }) : navigate('/me?noRedirect=1'))}
    //   size="mini"
    //   className="groupbuy-button"
    //   shape="rounded"
    //   color="primary"
    //   fill="outline"
    //   loading={loading}
    // >
    //   朋友拼单
    // </Button>
	<span></span>
  );
}
export default GroupBuyButton;
